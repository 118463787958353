<template>
  <div>
    <b-form>
      <b-row>

        <!-- Field: Name -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
          >
            <b-form-group>
              <h5>Contact Name</h5>
              <b-form-input
                  id="name"
                  v-model="contactData.name"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
        <!-- Field: Surname -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="Surname"
           
          >
            <b-form-group>
              <h5>Surname</h5>
              <b-form-input
                  id="surname"
                  v-model="contactData.surname"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
        <!-- Field: Phone Number -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="phoneNumber"
              rules="required"
          >
            <b-form-group>
              <h5>Phone Number</h5>
              <b-form-input
                  id="phoneNumber"
                  v-model="contactData.phoneNumber"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
        <!-- Field: Department -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="Department"
              rules="required"
          >
            <b-form-group>
              <h5>Department</h5>
              <b-form-input
                  id="department"
                  v-model="contactData.department"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
        <!-- Field: Title -->
        <b-col
            cols="12"
            md="6"
        >
          <validation-provider
              #default="validationContext"
              name="Title"
             
          >
            <b-form-group>
              <h5>Title</h5>
              <b-form-input
                  id="title"
                  v-model="contactData.title"
                  :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
      </b-row>

    </b-form>
    <!-- Action Buttons -->
    <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="onSubmit"
    >
      Save Changes
    </b-button>
    <b-button
        variant="outline-secondary"
        type="reset"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        :to="{name: 'contacts-page'  }"
    >
      Cancel
    </b-button>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import {
  BFormInvalidFeedback,VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,BForm
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
export default {
  name: 'ContactEditTab',
  components: {
    flatPickr,
    ToastificationContent,
    vSelect,VBToggle, VBModal,BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BFormInvalidFeedback,BBadge, BDropdown, BDropdownItem, BPagination,BFormGroup,BFormSelect,BSidebar,BForm,
    ValidationProvider,
    ValidationObserver
  },
    props: {
    contactData: {
      type: Object,
      required: true,

    }
  },
  setup(props){
    const blankContactData = {
      Name: '',
      Surname: '',
      PhoneNumber: '',
      Department: '',
      Title: 'null'

    }

    const contactData = ref(JSON.parse(JSON.stringify(blankContactData)))
    const resetcontactData = () => {
      contactData.value = JSON.parse(JSON.stringify(blankContactData))
    }

    const toast = useToast()
    const onSubmit = () => {
      console.log(props.contactData.name)
      store.dispatch('updateContact',props.contactData)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Success updating contact',
                icon: 'BellIcon',
                variant: 'success',
              },
            })
            router.push({name:'contacts-page'})
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error updating contact',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })

          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetcontactData)

    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,


    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
